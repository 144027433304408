import Store from '../store'
import axios from 'axios'
import Cookies from 'universal-cookie'
import fileDownload from 'js-file-download'
const cookies = new Cookies()

const makeGetRequest = async (url) => {
  try {
    const res = await axios.get(url)
    return res.data
  } catch (e) {
    console.log('AXIOS ERROR: ', e)
    return e.response.data
  }
}

const makePostRequest = async (url, body) => {
  try {
    const res = await axios.post(url, JSON.stringify(body), {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': cookies.get('csrftoken')
      },
      credentials: 'same-origin'
    })
    return { data: res.data, status: res.status }
  } catch (e) {
    console.log('AXIOS ERROR: ', e)
    if (e.response) {
      return { data: e.response.data, status: e.response.status }
    } else {
      return { data: { message: 'An unexpected error occurred.' }, status: 500 }
    }
  }
}

const makePostFormRequest = async (url, form_data) => {
  try {
    const res = await axios.post(url, form_data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-CSRFToken': cookies.get('csrftoken')
      },
      credentials: 'same-origin'
    })
    return res.data
  } catch (e) {
    console.log('AXIOS ERROR: ', e)
  }
}

const makePutRequest = async (url, body) => {
  try {
    const res = await axios.put(url, JSON.stringify(body), {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': cookies.get('csrftoken')
      },
      credentials: 'same-origin'
    })
    return res.data
  } catch (e) {
    console.log('AXIOS ERROR: ', e)
  }
}

const makeDeleteRequest = async (url) => {
  try {
    const res = await axios.delete(url, {
      headers: {
        'X-CSRFToken': cookies.get('csrftoken')
      },
      credentials: 'same-origin'
    })
    return res.data
  } catch (e) {
    console.log('AXIOS ERROR: ', e)
  }
}

const getCSRF = () =>
  axios.get('/api/csrf/').then(
    (res) => {
      Store.setState({ csrf: res.data.csrfToken })
      console.log({ res })
    },
    (err) => {
      console.log('err is : ', err)
    }
  )

export const getSurveyList = async () => {
  const data = await makeGetRequest(`/api/survey-list/`)
  return data
}

const getSurveyListPage = async (page, resultsPerPage) => {
  const data = await makeGetRequest(
    `/api/survey-list-page/${page}/${resultsPerPage}`
  )
  Store.setState({
    surveys: data.items,
    surveyPage: page,
    surveysPerPage: resultsPerPage
  })
  return data
}

const getSurveys = async () => {
  const data = await makeGetRequest(`/api/survey-list/`)
  Store.setState({ surveys: data })
}

const getSurvey = async (id) => {
  const data = await makeGetRequest(`/api/get-survey/${id}`)
  return data
}

const getSurveyJWT = async (jwt) => {
  const data = await makeGetRequest(`/api/get-survey-jwt/${jwt}`)
  return data
}

const collectSurvey = async (id, collector_id, emails) => {
  const data = await makePostRequest(
    `/api/collect-survey/${id}/${collector_id}/`,
    emails
  )
  return data
}

const collectorLock = async (collector_id, locked) => {
  const data = await makePostRequest(`/api/collector-lock/${collector_id}`, {
    locked
  })
  getSurveys()
  return data
}

const deleteSurvey = async (id) => {
  await makeGetRequest(`/api/delete-survey/${id}`)
}

const saveSurvey = async (body) => {
  const data = await makePostRequest('/api/save-survey/', body)
  getSurveys()
  return data
}

const saveAnswer = async (jwt, body) => {
  const { data, status } = await makePostRequest(
    `/api/save-answer/${jwt}`,
    body
  )

  switch (status) {
    case 200:
      return { success: true, message: 'Survey submitted successfully!' }
    case 400:
      console.error('saveSurvey Error: ', data)
      return { success: false, message: data.msg }
    case 423:
      console.error('saveSurvey Error: ', data)
      return { success: false, message: data.msg }
    default:
      console.error('saveSurvey Unexpected Error: ', data)
      return { success: false, message: 'An unexpected error occurred.' }
  }
}

const saveAnswerTmp = async (jwt, body) => {
  await makePostRequest(`/api/save-answer-tmp/${jwt}`, body)
}

const duplicateSurvey = async (id) => {
  await makeGetRequest(`/api/duplicate-survey/${id}`)
}

const generateWebLink = async (id, collector_id, email) => {
  const data = await makeGetRequest(
    `/api/generate-weblink/${id}/${collector_id}/${email}`
  )
  return data
}

const generatePublicWebLink = async (survey_id, collector_id) => {
  const data = await makeGetRequest(
    `/api/generate-public-weblink/${survey_id}/${collector_id}`
  )
  return data
}

const createCollector = async (id) => {
  const data = await makeGetRequest(`/api/create-collector/${id}`)
  return data
}

const getCollectorsForSurvey = async (s_id) => {
  const data = await makeGetRequest(`/api/get-collectors-for-survey/${s_id}`)
  return data
}

const saveCollector = async (surveyId, collectorId, body) => {
  const data = await makePutRequest(
    `/api/collector/${surveyId}/${collectorId}/`,
    body
  )
  return data
}

const collectPublicSurvey = async (surveyId, collectorId) => {
  const data = await makePostRequest(
    `/api/collect-public-survey/${surveyId}/${collectorId}/`,
    {}
  )
  await getSurveys()
  return data
}

const sentTestEmail = async (body) => {
  const data = await makePutRequest(`/api/test-email/`, body)
  return data
}

const deleteCollector = async (surveyId, collectorId) => {
  const data = await makeDeleteRequest(
    `/api/collector/${surveyId}/${collectorId}/`
  )
  await getSurveys()
  return data
}

const uploadImage = async (imageFile) => {
  const dt = new FormData()
  dt.append('image', imageFile)
  const image_data = await makePostFormRequest(`/api/upload-image/`, dt)
  return image_data
}

const scheduleFunc = async () => {
  const data = await makeGetRequest(`/api/schedule-func/`)
  return data
}

const collectCSV = async (id) => {
  const data = await makeGetRequest(`/api/collect-csv/${id}`)
  try {
    fileDownload(data, `prompta_output_${Date.now().toString()}.csv`)
  } catch (e) {
    console.log(e)
  }
  return data
}

const getToken = async () => {
  const { token } = await makeGetRequest(`/api/get-token/`)
  return token
}

const collectLikert = (id) =>
  axios
    .get(`/api/collect-likert/${id}`, {
      responseType: 'blob'
    })
    .then((res) => {
      fileDownload(
        res.data,
        `prompta_output_likert_xlsx_${Date.now().toString()}.xlsx`
      )
    })

const collectXLSX = (id) =>
  axios
    .get(`/api/collect-xlsx/${id}`, {
      responseType: 'blob'
    })
    .then((res) => {
      fileDownload(
        res.data,
        `prompta_output_demog_xlsx_${Date.now().toString()}.xlsx`
      )
    })

const api = {
  getCSRF,
  collectXLSX,
  collectLikert,
  getToken,
  getSurveys,
  getSurveyListPage,
  saveSurvey,
  getSurvey,
  deleteSurvey,
  collectSurvey,
  getSurveyJWT,
  saveAnswer,
  saveAnswerTmp,
  duplicateSurvey,
  generateWebLink,
  generatePublicWebLink,
  createCollector,
  getCollectorsForSurvey,
  saveCollector,
  deleteCollector,
  uploadImage,
  scheduleFunc,
  collectCSV,
  collectorLock,
  sentTestEmail,
  collectPublicSurvey
}

export default api
